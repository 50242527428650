* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  margin-top: 112px;
}

.header {
  border-color: #5d5fef;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  border-bottom-width: 2px;
  border-bottom-color: #5d5fef;
  padding-top: 25px;
  /* padding-left: 50px; */
  padding-bottom: 2px;
  /* background-color: red; */
}

button {
  height: 50px;
  width: 150px;
  font-family: "Figtree", sans-serif;
  border-width: 2px;
  border-color: rgba(93, 95, 239, 0.3);
  background-color: white;
  border-radius: 0.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
  color: gray;
  font-size: medium;
  cursor: pointer;
  font-weight: 500;
}
